import { ArgJSONMap } from "./argjsonmap"
import { UserType } from "../contexts/user"
import { IProfileCardProps } from "../components/Organisms/HorizontalProfileCard"
import {
  IPromoDetailsProps,
  ISocialAccountProps,
} from "../components/Promo/types"
import { Brands, getLogo } from "../components/Atoms/Profile/Header/Logo"
import { IPromo, ScheduleRole } from "../pages/orders"
import { IOrderPromoPhoto } from "../components/Atoms/Promo/AddMedia"
import { IProfileCardStat } from "../components/Atoms/ProfileCardStatsDisplay"
import { parseLocaleNumberShort } from "./numberTruncate"
import { IServerBlackoutDate } from "./dates"
import { WafflesType } from "../contexts/waffles"
import { IPromoTier } from "../components/Molecules/Promo/CreatePromo"

export function parseLoginData(data: ArgJSONMap): UserType {
  return {
    avatarUrl: data.getString("avatar_url"),
    theme: data.getString("theme") === "light" ? "light" : "dark",
    email: data.getString("email"),
    isEmailValidated: data.getBoolean("is_email_validated"),
    phoneNumber: data.getString("phone_number"),
    isPhoneValidated: data.getBoolean("is_phone_validated"),
    isTfaEnabled: data.getBoolean("is_tfa_enabled"),
    tfaDevice: data.getString("tfa_device"),
    showOnboardingBar: data.getBoolean("show_onboarding_bar"),
    accountAgeInDays: data.getNumber("account_age_in_days"),
    id: parseInt(data.getString("profile_id")),
    // userId: parseInt(data.getString("user_id")),
    userUuid: data.getString("user_uuid"),
    username: data.getString("username"),
    uuid: data.getString("uuid"),
    profileName: data.getString("profile_name"),
    profileDisplayName: data.getString("profile_display_name"),
    isAuthenticated: true,
    authenticationAttempted: true,
    verified: data.getBoolean("verified"),
    isCustomerSupport: data.getBoolean("customer_support"),
    promoRequestsRemaining: data.getNumber("promo_request_limit_remaining"),
    userIdVerified: data.getBoolean("id_verified"),
    buyerAgreementAccepted: data.getBoolean("buyer_agreement_accepted"),
    sellerAgreementAccepted: data.getBoolean("seller_agreement_accepted"),
    isEndorsed: data.getBoolean("is_endorsed"),
    isStaff: data.getBoolean("is_staff"),
    userRole: data.getString("user_role"),
    location: data.getString("location"),
    tags: data.getListOfType<number>("tags"),
    blackout_dates_iso_list: data.getListOfType<IServerBlackoutDate>(
      "blackout_dates_iso_list"
    ),
    telegramUsername: data.getString("telegram_username") || "",
    telegramChatId: data.getString("telegram_chat_id") || "",
    telegramUrl: data.getString("telegram_url") || "",
  }
}

export function parseRegisterData(data: ArgJSONMap): UserType {
  return {
    avatarUrl: data.getString("avatarUrl"),
    theme: data.getString("theme") === "light" ? "light" : "dark",
    email: data.getString("email"),
    isEmailValidated: data.getBoolean("is_email_validated"),
    phoneNumber: data.getString("phone"),
    isPhoneValidated: data.getBoolean("is_phone_validated"),
    isTfaEnabled: data.getBoolean("is_tfa_enabled"),
    tfaDevice: data.getString("tfa_device"),
    showOnboardingBar: data.getBoolean("show_onboarding_bar"),
    accountAgeInDays: data.getNumber("account_age_in_days"),
    id: parseInt(data.getString("id")),
    userUuid: data.getString("user_uuid"),
    username: data.getString("username"),
    uuid: data.getString("uuid"),
    profileName: "",
    profileDisplayName: "",
    isAuthenticated: true,
    authenticationAttempted: true,
    verified: data.getBoolean("verified"),
    isCustomerSupport: data.getBoolean("customer_support"),
    promoRequestsRemaining: data.getNumber("promo_request_limit_remaining"),
    userIdVerified: data.getBoolean("id_verified"),
    buyerAgreementAccepted: data.getBoolean("buyer_agreement_accepted"),
    sellerAgreementAccepted: data.getBoolean("seller_agreement_accepted"),
    isEndorsed: data.getBoolean("is_endorsed"),
    isStaff: data.getBoolean("is_staff"),
    tags: data.getListOfType<number>("tags"),
    userRole: data.getString("user_role"),
    location: data.getString("location"),
    blackout_dates_iso_list: data.getListOfType<IServerBlackoutDate>(
      "blackout_dates_iso_list"
    ),
    telegramUsername: data.getString("telegram_username") || "",
    telegramChatId: data.getString("telegram_chat_id") || "",
    telegramUrl: data.getString("telegram_url") || "",
  }
}

export interface ISocialMediaLink {
  platformName: string
  accountName: string
  url: string
}

// interface ISocialLink {
//
// }

export interface IAuthProfileAPIResponse {
  name: string
  displayName: string
  uuid: string
  aboutMe: string[]
  languages: string
  avatarThumbnail: string
  avatarCropped: string
  socialMediaPlatforms: string[]
  socialMediaLinks: ISocialMediaLink[]
  //followersByPlatforms: ISocialLink[]
  rating: number
  numReviews: number
  online: boolean
  verified: boolean
  isCustomerSupport: boolean
  nextNameEdit: string
  hasCustomAvatar: boolean
  profileAvatarSet: boolean
  userRole: string
  location: string
  tags: number[]
  blackout_dates_iso_list: IServerBlackoutDate[]
}

export function parseAuthProfileGetResponse(
  data: ArgJSONMap
): IAuthProfileAPIResponse {
  const linksRaw = data.getList("social_media_links") ?? []
  const socialMediaLinks = linksRaw.map((link) => {
    return {
      platformName: link.getString("platform_name"),
      url: link.getString("url"),
      accountName: link.getString("social_account_username"),
    }
  })
  // const followersByPlatforms = data
  //     .getListOfType<ISocialLink>("followers_by_platforms")
  //     ?.map((social: ISocialLink) => {
  //       const socialData = new ArgJSONMap(JSON.stringify(social))
  //       return {
  //         name: socialData.getString("social_name"),
  //         followerCount: socialData.getNumber("followers"),
  //       }
  //     })
  return {
    name: data.getString("name"),
    displayName: data.getString("display_name"),
    uuid: data.getString("uuid"),
    aboutMe: data.getListOfType<string>("about_me"),
    languages: data.getString("languages"),
    avatarThumbnail: data.getString("avatar_thumbnail"),
    avatarCropped: data.getString("avatar_cropped"),
    socialMediaPlatforms: data.getListOfType<string>("social_media_platforms"),
    //followersByPlatforms: followersByPlatforms,
    socialMediaLinks: socialMediaLinks,
    rating: data.getNumber("rating"),
    numReviews: data.getNumber("num_reviews"),
    online: data.getBoolean("online"),
    verified: data.getBoolean("verified"),
    isCustomerSupport: data.getBoolean("customer_support"),
    nextNameEdit: data.getString("next_name_modify_string"),
    hasCustomAvatar: data.getBoolean("profile_avatar_set"),
    profileAvatarSet: data.getBoolean("profile_avatar_set"),
    tags: data.getListOfType<number>("tags"),
    userRole: data.getString("user_role"),
    location: data.getString("location"),
    blackout_dates_iso_list: data.getListOfType<IServerBlackoutDate>(
      "blackout_dates_iso_list"
    ),
  }
}

/**
 * Returns string for chat message timestamp in local language and date format. If chat has been sent within 10 minutes
 * then the return string is empty as we don't need timestamps for recent messages.
 * @param timestamp: number
 * @param date: Date
 */
export const parseTimestampForLocaleDateString = (
  timestamp: number,
  date: Date,
  alwaysShow = false
): string => {
  const deltaTimeSeconds = new Date().getTime() / 1000 - timestamp
  const dayOfMonth = new Date().getDate()
  const currentMonth = new Date().getMonth()
  const tenMinutesSeconds = alwaysShow ? 0 : 600
  const oneHourSeconds = 3600
  const twelveHoursInSeconds = 43200
  let dateString: string
  if (isNaN(date.getTime()) || deltaTimeSeconds < tenMinutesSeconds) {
    // Back to back messages don't put dates or times on them as it'll spam the UI
    dateString = ""
  } else if (
    deltaTimeSeconds <= oneHourSeconds &&
    deltaTimeSeconds >= tenMinutesSeconds
  ) {
    const minutes = deltaTimeSeconds / 60
    if (minutes < 1) {
      dateString = "Just now"
    } else {
      dateString = `${Math.floor(minutes)} minute${minutes < 2 ? "" : "s"} ago`
    }
  } else if (
    deltaTimeSeconds > oneHourSeconds &&
    deltaTimeSeconds < twelveHoursInSeconds
  ) {
    if (date.getDate() === dayOfMonth) {
      dateString = date.toLocaleString(window.navigator.language, {
        hour: "numeric",
        minute: "2-digit",
      })
    } else {
      dateString = date.toLocaleString(window.navigator.language, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      })
    }
  } else if (
    dayOfMonth - date.getDate() < 7 &&
    currentMonth === date.getMonth()
  ) {
    dateString = `${date.toLocaleString(window.navigator.language, {
      weekday: "long",
    })} ${date.toLocaleTimeString(window.navigator.language, {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })}`
  } else {
    dateString = date.toLocaleDateString()
  }
  return dateString
}

function parsePlatformStat(
  platform: string,
  data: ArgJSONMap,
  index: number,
  statsLength: number
): string {
  let label: string
  if (platform.toLowerCase() === "onlyfans") {
    label = `Top ${parseLocaleNumberShort(
      data.getNumber("top_rated_percent")
    )}% ${parseLocaleNumberShort(data.getNumber("fans"))} Subs`
  } else if (index === 1 && statsLength === 1) {
    label = `${parseLocaleNumberShort(
      data.getNumber("followers_count")
    )} Followers`
  } else {
    label = parseLocaleNumberShort(data.getNumber("followers_count"))
  }
  return label
}

export function parseProfileCardsResponse(
  profiles: ArgJSONMap[]
): IProfileCardProps[] {
  return profiles.map((profile) => {
    const categories = profile.getListOfType<string>("categories") ?? []
    const links = profile
      .getListOfType<ISocialMediaLink>("social_account_links")
      ?.map((link: ISocialMediaLink) => {
        const data = ArgJSONMap.fromParsedJson(link)
        const platformName = data.getString("platform_name")
        return {
          platformName: platformName,
          url: data.getString("url"),
          accountName: data.getString("social_account_username"),
        }
      })
    let stats =
      profile.getListOfType<IProfileCardStat>("social_account_stats") ?? []

    stats = stats
      .filter(
        (stat: IProfileCardStat) => stat.platform.toLowerCase() === "onlyfans"
      )
      .concat(
        stats.filter(
          (stat: IProfileCardStat) => stat.platform.toLowerCase() !== "onlyfans"
        )
      )

    stats = stats.map((stat: IProfileCardStat, index: number) => {
      const data = ArgJSONMap.fromParsedJson(stat)
      const platform = data.getString("platform")
      const isStatsActive = data.getString("is_details_active") === "True"

      return {
        platform: platform as Brands,
        label: isStatsActive
          ? parsePlatformStat(platform, data, index, stats.length)
          : "",
        url: data.getString("url"),
      }
    })

    return {
      verified: profile.getBoolean("verified"),
      image: profile.getString("avatar_cropped"),
      name: profile.getString("profile_name"),
      accounts: { links: links },
      blurb: profile.getListOfType<string>("about_me"),
      rating: {
        rating: profile.getNumber("rating"),
        numberOfReviews: profile.getNumber("num_reviews"),
      },
      categories: categories,
      lowestAllRolePrice: profile.getNumber("min_promo_all_cost"),
      lowestBrandsOnlyPrice:
        profile.getNumber("min_promo_brand_cost") ??
        profile.getNumber("min_promo_cost"),
      lowestCreatorsOnlyPrice:
        profile.getNumber("min_promo_creator_cost") ??
        profile.getNumber("min_promo_cost"),
      displayName: profile.getString("profile_display_name"),
      stats: stats,
      tags: profile.getListOfType<string>("tags"),
      role: profile.getString("role"),
    }
  })
}

export function parseHomepageProfileQueryResponse(
  profiles: ArgJSONMap[]
): IProfileCardProps[] {
  return profiles.map((profile) => {
    const links = profile
      .getListOfType<ISocialMediaLink>("social_account_links")
      ?.map((link: ISocialMediaLink) => {
        const data = ArgJSONMap.fromParsedJson(link)
        const platformName = data.getString("platform_name")
        return {
          platformName: platformName,
          url: data.getString("url"),
          accountName: data.getString("social_account_username"),
        }
      })
    let stats =
      profile.getListOfType<IProfileCardStat>("social_account_stats") ?? []

    stats = stats
      .filter(
        (stat: IProfileCardStat) => stat.platform.toLowerCase() === "onlyfans"
      )
      .concat(
        stats.filter(
          (stat: IProfileCardStat) => stat.platform.toLowerCase() !== "onlyfans"
        )
      )

    stats = stats.map((stat: IProfileCardStat, index) => {
      const data = ArgJSONMap.fromParsedJson(stat)
      const platform = data.getString("platform")
      const isStatsActive = data.getString("is_details_active") === "True"

      return {
        platform: data.getString("platform") as Brands,
        label: isStatsActive
          ? parsePlatformStat(platform, data, index, stats.length)
          : "",
        url: data.getString("url"),
      }
    })

    return {
      image: profile.getString("avatar_cropped"),
      name: profile.getString("profile_name"),
      rating: {
        rating: profile.getNumber("rating"),
        numberOfReviews: profile.getNumber("num_reviews"),
      },
      verified: profile.getBoolean("verified"),
      accounts: { links: links },
      blurb: profile.getListOfType<string>("about_me"),
      lowestAllRolePrice: profile.getNumber("min_promo_cost"),
      lowestBrandsOnlyPrice:
        profile.getNumber("min_promo_brand_cost") ??
        profile.getNumber("min_promo_cost"),
      lowestCreatorsOnlyPrice:
        profile.getNumber("min_promo_creator_cost") ??
        profile.getNumber("min_promo_cost"),
      displayName: profile.getString("profile_display_name"),
      stats: stats,
      tags: profile.getListOfType<string>("tags"),
      role: profile.getString("role"),
    }
  })
}

export function parsePromoDetailsQueryResponse(
  promo: ArgJSONMap
): IPromoDetailsProps {
  return {
    title: promo.getString("title"),
    userName: promo.getString("userName"),
    description: promo.getString("description"),
    tiers:
      promo.getListOfType<IPromoTier>("tiers").map((tier: IPromoTier) => {
        return {
          packageId: tier.uuid || "",
          price: tier.price,
          description: tier.description,
          quantity: tier.quantity,
          duration: tier.duration,
          duration_type: tier.duration_type,
        }
      }) ?? [],
  }
}

export function parseSocialAccountsQueryResponse(
  accounts: ArgJSONMap[]
): ISocialAccountProps[] {
  return accounts.map((account) => {
    return {
      id: account.getNumber("id"),
      profile: account.getString("profile"),
      socialMediaType: account.getString("social_media_type"),
      socialAccountUsername: account.getString("social_account_username"),
      followers: account.getNumber("followers"),
      isVerified: account.getBoolean("is_verified"),
      url: "",
      isFansMetricConnected: account.getBoolean(
        "is_fans_metric_connected",
        false
      ),
    }
  })
}

export function parsePromoRequestList(
  promoRequests: ArgJSONMap[] | undefined,
  role: ScheduleRole,
  myProfileName: string
): IPromo[] {
  if (promoRequests === undefined) {
    return []
  } else {
    const parsedPromoRequests = promoRequests?.map((promoRequest) => {
      const buyerProfile = new ArgJSONMap(
        promoRequest.getObjectString("buyer_profile")
      )
      const sellerProfile = new ArgJSONMap(
        promoRequest.getObjectString("seller_profile")
      )
      const promoData = new ArgJSONMap(promoRequest.getObjectString("promo"))
      const promoTierData = new ArgJSONMap(
        promoRequest.getObjectString("promo_tier")
      )
      const stateChangeTimestamps =
        promoRequest.getList("state_changes")?.map((stateChange) => {
          return stateChange.getString("time")
        }) ?? []
      return {
        id: promoRequest.getString("uuid"),
        logo: getLogo(
          promoRequest.getString("social_account_type_name"),
          false
        ),
        title: promoRequest.getString("promo_type_name"),
        userName:
          role === ScheduleRole.Selling
            ? buyerProfile.getString("name")
            : sellerProfile.getString("name"),
        description: promoData.getString("description"),
        orderId: promoRequest.getString("uuid"),
        actionTime: new Date(promoRequest.getString("promo_time")),
        hasNewChanges: true, // element.getBoolean(""), // todo: figure out this
        promoUuid: promoData.getString("uuid"),
        promoTierUuid: promoTierData.getString("uuid"),
        userRole: role,
        location: promoData.getString("location"),
        status: promoRequest.getString("status"),
        lastUpdateDate:
          stateChangeTimestamps.length > 0
            ? new Date(stateChangeTimestamps[stateChangeTimestamps.length - 1])
            : undefined,
        nextAvailableDay: new Date(promoData.getString("next_available_day")),
        reviewedByMe: parseReviewedByMe(promoRequest, myProfileName),
        price: parseFloat(promoRequest.getString("price")),
        buyerPhoto: buyerProfile.getString("avatar_thumbnail"),
        sellerPhoto: sellerProfile.getString("avatar_thumbnail"),
        brand: new ArgJSONMap(
          new ArgJSONMap(promoData.getObjectString("type")).getObjectString(
            "social_media_type"
          )
        ).getString("name") as Brands,
        orderNumber: "fake",
        disputeStatus: promoRequest.getString("dispute_status"),
        disputeOutcome: promoRequest.getString("dispute_outcome"),
      }
    })
    return parsedPromoRequests ?? []
  }
}

function parseReviewedByMe(
  promoRequest: ArgJSONMap,
  myProfileName: string
): boolean {
  let reviewedByMe = false
  promoRequest.getList("submitted_reviews")?.forEach((review) => {
    if (review.getString("reviewer_profile_name") === myProfileName) {
      reviewedByMe = true
    }
  })
  return reviewedByMe
}

export function parsePhotos(rawPhotoList: object[]): IOrderPromoPhoto[] {
  const photos = rawPhotoList.map((x: object) => {
    const photo = ArgJSONMap.fromParsedJson(x)
    return {
      id: photo.getNumber("id"),
      thumbnailUrl: photo.getString("thumbnail_url"),
      originalUrl: photo.getString("original_url"),
      isVideo: photo.getBoolean("is_video"),
      isPublic: photo.getBoolean("is_public"),
      selected: false,
    }
  })
  return photos
}

export function parseWaffles(waffle_map: ArgJSONMap): WafflesType {
  return {
    type: waffle_map.getString("type"),
    flags: Object.values(waffle_map.getObject("flags")).map(
      (item: { name: string }) => {
        return item.name
      }
    ),
    switches: Object.values(waffle_map.getObject("switches")).map(
      (item: { name: string }) => {
        return item.name
      }
    ),
  }
}
