import styled from "@emotion/styled"
import { ArrowBack, MoreVertOutlined } from "@mui/icons-material"
import { Avatar, IAvatarProps } from "../Avatar"
import {
  Alert,
  Divider,
  Drawer,
  Menu,
  MenuItem,
  Snackbar,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { Verified } from "../Verified"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useRouter } from "next/router"
import { messagesWidth } from "../../../utils/constants"
import { ReportDialog } from "./ReportDialog"
import { get, patch, post } from "../../../utils/requests"
import { ChatActionTypes, ChatContext } from "../../../contexts/chat"
import { InverseButton } from "../Buttons"
import { UserContext } from "../../../contexts/user"
import { StaffBadge } from "../StaffBadge"
import { MessageActionTypes, MessageContext } from "../../../contexts/websocket"
import { ArgJSONMap } from "../../../utils/argjsonmap"
import ProfileRoleBadge, {
  roleBadgeSizeOptions,
  roleOptions,
} from "../ProfileRoleBadge"

export interface IChatHeaderProps {
  avatar: IAvatarProps
  username: string
  role: string
  otherUserUuid: string
  verified: boolean
  isCustomerSupport: boolean
}

export const ChatHeader = (props: IChatHeaderProps): JSX.Element => {
  const router = useRouter()
  const theme = useTheme()
  const { dispatch: chatDispatch } = useContext(ChatContext)
  const { messageDispatch } = useContext(MessageContext)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [reportDrawerOpen, setReportDrawerOpen] = useState<boolean>(false)
  const [isIgnored, setIsIgnored] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>("")
  const { context } = useContext(UserContext)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const currentUserUuid = useMemo(() => context.uuid, [context.uuid])
  const avatar = useMemo(() => props.avatar, [props.avatar])
  const username = useMemo(() => props.username, [props.username])
  const role = useMemo(() => props.role, [props.role])
  const otherUserUuid = useMemo(
    () => props.otherUserUuid,
    [props.otherUserUuid]
  )
  const isVerified = useMemo(() => props.verified, [props.verified])
  const isCustomerSupport = useMemo(
    () => props.isCustomerSupport,
    [props.isCustomerSupport]
  )

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setMenuAnchor(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setMenuAnchor(null)
  }

  const handleIgnoreChange = (): void => {
    const method = isIgnored ? patch : post
    method("/ignored_users/", {
      "other_profile_id": otherUserUuid,
      "profile_uuid": currentUserUuid,
    }).then((response) => {
      setIsIgnored(Boolean(response.data))
      setSnackbarMessage(
        `You are ${response.data ? "now" : "no longer"} ignoring ${username}.`
      )
      setTimeout(handleMenuClose, 500)
    })
  }

  const checkIfUserIsIgnored = (): boolean => {
    get(
      `/is_user_ignored/?other_user_id=${otherUserUuid}&profile_uuid=${currentUserUuid}`
    ).then((response) => {
      setIsIgnored(Boolean(response.data))
      return true
    })
    return false
  }

  useEffect(() => {
    if (otherUserUuid) {
      const isIgnored = checkIfUserIsIgnored()
      if (!isIgnored) {
        const postData = {
          other_profile_id: otherUserUuid,
          profile_id: currentUserUuid,
        }
        post("/update_messages_as_read/", postData)
          .then((response) => {
            const data = ArgJSONMap.fromParsedJson(response.data)
            messageDispatch({
              type: MessageActionTypes.UpdateUnreadMessageCount,
              payload: {
                unreadMessageCount: data.getNumber("total_unread"),
              },
            })
          })
          .catch((errors) => console.error(errors))
      }
    }
  }, [otherUserUuid])

  return (
    <StyledMessageHeader theme={theme}>
      <ArrowBack
        className="back"
        onClick={() => {
          chatDispatch({
            type: ChatActionTypes.ChatWithUser,
            payload: "",
          })
          messageDispatch({
            type: MessageActionTypes.ChatConnected,
            payload: { "otherProfileUUID": "" },
          })
          if (isMobile || isTablet) {
            // Don't ask me why this is the intended behavior
            router.push("/messages")
          } else {
            chatDispatch({
              type: ChatActionTypes.SetMessageDrawerOpen,
              payload: true,
            })
          }
        }}
      />
      <Avatar
        online={avatar.online}
        profileImage={avatar.profileImage}
        size={"Medium"}
      />
      <div className="header-text">
        <div className="upper">
          <Typography variant={"subtitle2"}>{username}</Typography>
          {isVerified && <Verified size="Small" />}
          {role === roleOptions.brandOrBusiness && (
            <ProfileRoleBadge
              roleOption={role as roleOptions}
              size={roleBadgeSizeOptions.xSmall}
              showLabel={false}
            />
          )}
          {isCustomerSupport && (
            <StaffBadge expand={true} sx={{ top: "0px" }} />
          )}
        </div>
      </div>
      <InverseButton
        sx={{
          cursor: "pointer",
          marginLeft: "auto",
          color: theme.palette.primary.main,
        }}
        onClick={handleMenuClick}
      >
        <MoreVertOutlined sx={{ color: "inherit" }} />
      </InverseButton>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose()
            router.push(`/${username}`)
          }}
        >
          View Profile
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleMenuClose()
            setReportDrawerOpen(true)
          }}
        >
          Report
        </MenuItem>
        <MenuItem>
          Ignore <Switch checked={isIgnored} onChange={handleIgnoreChange} />
        </MenuItem>
      </Menu>

      <Drawer
        anchor="bottom"
        open={reportDrawerOpen}
        onClose={() => {
          setReportDrawerOpen(false)
        }}
        PaperProps={{
          sx: {
            [theme.breakpoints.up("sm")]: {
              width: messagesWidth,
              left: "unset",
            },
          },
        }}
      >
        <ReportDialog
          abuser={username}
          onSubmit={() => {
            setReportDrawerOpen(false)
            setSnackbarMessage("Your report has been submitted.")
          }}
        />
      </Drawer>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(snackbarMessage)}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbarMessage("")
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            setSnackbarMessage("")
          }}
          sx={{
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledMessageHeader>
  )
}

const StyledMessageHeader = styled.div<{ theme: Theme }>`
  ${(props) => props.theme.breakpoints.up("sm")} {
    height: 100%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    position: fixed;
    top: 0;
  }
  display: flex;
  gap: 5px;
  background: white;
  width: 100%;
  padding: 12px;
  align-items: center;
  box-shadow: 0 0 4px 0 #000000;
  z-index: 999;

  .back {
    cursor: pointer;
  }

  .header-text {
    display: flex;
    display: flex;
    flex-direction: column;
    .upper {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
`
