import { IconButton, SxProps, Tooltip, Typography } from "@mui/material"
import { theme } from "../../../styles/Theme"
import { StorefrontOutlined, WorkOutlined } from "@mui/icons-material"
import SmallLogo from "../assets/BlossmLogoSmallWhite.svg"
import React from "react"
import { IRole } from "../Organisms/Settings/ChangeRole"
import { Theme } from "@mui/material/styles"
import { Box } from "@mui/system"

export const enum roleOptions {
  brandOrBusiness = "BrandOrBusiness",
  agency = "Agency",
  independentCreator = "IndependentCreator",
}
export const getRoleSubtitle = (role: roleOptions): string => {
  switch (role) {
    case roleOptions.independentCreator:
    case roleOptions.agency:
      return "Available to Creators"
    case roleOptions.brandOrBusiness:
      return "Available to Brands & Businesses"
    default:
      return ""
  }
}

export const getRole = (role: roleOptions): IRole => {
  switch (role) {
    case roleOptions.independentCreator:
      return {
        title: "Creators",
        subtitle: "is a creator",
        role: roleOptions.independentCreator,
      }
    case roleOptions.brandOrBusiness:
      return {
        title: "Brand or Business",
        subtitle: "is a brand or business",
        role: roleOptions.brandOrBusiness,
      }
    case roleOptions.agency:
      return {
        title: "Agency",
        subtitle: "is an agency",
        role: roleOptions.agency,
      }
  }
}
export enum roleBadgeSizeOptions {
  xSmall = "xSmall",
  small = "small",
  medium = "medium",
  large = "large",
}
interface IProfileRoleDisplay {
  roleOption: roleOptions
  size: roleBadgeSizeOptions
  showLabel: boolean
  top?: { top: string }
}

const getRoleBadgeLabel = (roleOption: roleOptions): string => {
  switch (roleOption) {
    case roleOptions.brandOrBusiness:
      return "Business"
    case roleOptions.agency:
      return "Agency"
    case roleOptions.independentCreator:
      return "Creator"
  }
}

const getIconStyling = (
  size: roleBadgeSizeOptions
): { width: string; height: string } => {
  switch (size) {
    default:
    case roleBadgeSizeOptions.xSmall:
      return {
        width: "8px",
        height: "8px",
      }
    case roleBadgeSizeOptions.small:
      return {
        width: "10px",
        height: "10px",
      }
    case roleBadgeSizeOptions.medium:
      return {
        width: "12px",
        height: "12px",
      }
    case roleBadgeSizeOptions.large:
      return {
        width: "13px",
        height: "13px",
      }
  }
}
interface IGetIconButtonStyling {
  size: roleBadgeSizeOptions
  showLabel: boolean
}
const getIconButtonStyling = ({
  size,
  showLabel,
}: IGetIconButtonStyling): SxProps<Theme> => {
  switch (size) {
    default:
    case roleBadgeSizeOptions.xSmall:
      return {
        width: showLabel ? "fit-content" : "14px",
        height: "14px",
        top: "1px",
        padding: "0px",
      }
    case roleBadgeSizeOptions.small:
      return {
        width: showLabel ? "fit-content" : "16px",
        height: "16px",
        top: "2px",
      }
    case roleBadgeSizeOptions.medium:
      return {
        width: showLabel ? "fit-content" : "18px",
        height: "18px",
        top: "0px",
      }
    case roleBadgeSizeOptions.large:
      return {
        width: showLabel ? "fit-content" : "20px",
        height: "20px",
        top: "3px",
      }
  }
}

const ProfileRoleBadge = (props: IProfileRoleDisplay): React.JSX.Element => {
  const { roleOption, size, showLabel } = props
  const role = getRole(roleOption)
  const sizeStyle = getIconButtonStyling({ size, showLabel })
  const iconStyle = getIconStyling(size)

  return (
    <Tooltip title={role.title}>
      <IconButton
        sx={{
          background: theme.palette.primary.main,
          ...sizeStyle,
          borderRadius: "64px",
          cursor: "default",
          ...props.top,
        }}
        disableRipple={true}
      >
        {role.role === roleOptions.brandOrBusiness && (
          <StorefrontOutlined sx={{ color: "white", ...iconStyle }} />
        )}
        {role.role === roleOptions.agency && (
          <WorkOutlined sx={{ color: "white", ...iconStyle }} />
        )}
        {role.role === roleOptions.independentCreator && (
          <Box
            component={"img"}
            width={iconStyle.width}
            height={iconStyle.height}
            alt={roleOptions.independentCreator}
            src={SmallLogo.src}
          />
        )}
        {showLabel && (
          <Typography
            component={"span"}
            pl={1}
            color={theme.palette.secondary.main}
            variant={"body1"}
          >
            {getRoleBadgeLabel(role.role)}
          </Typography>
        )}
      </IconButton>
    </Tooltip>
  )
}
export default ProfileRoleBadge
